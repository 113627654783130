var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{directives:[{name:"scroll",rawName:"v-scroll",value:(_vm.onScroll),expression:"onScroll"}],attrs:{"height":"70","color":'uniColor',"hide-on-scroll":_vm.$vuetify.breakpoint.smAndDown,"app":"","dark":"","elevate-on-scroll":"","fixed":"","id":"menuPrincipal"}},[_c('v-img',{staticClass:"shrink",attrs:{"alt":"Nic","max-width":"115","src":"/static/logoNic.png"}}),_c('v-spacer'),(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-toolbar-items',[_c('div',{staticClass:"mt-5"},_vm._l((_vm.items),function(item,i){return _c('v-btn',{key:i,attrs:{"active-class":!_vm.isScrolling ? 'primary--text' : undefined,"to":item.to,"text":""}},[_c('span',{domProps:{"textContent":_vm._s(item.text)}})])}),1),_c('div',{staticClass:"mt-5"},[_c('v-menu',{attrs:{"offset-y":"","open-on-hover":"","transition":"slide-y-transition","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"active-class":!_vm.isScrolling ? 'primary--text' : undefined,"text":""}},'v-btn',attrs,false),on),[_vm._v(" Nosotros ")])]}}],null,false,2142229666)},[_c('v-list',{attrs:{"flat":""}},_vm._l((_vm.items1),function(item,index){return _c('v-list-item',{key:index,staticClass:"bgsel",attrs:{"to":item.to,"text":""}},[_c('v-list-item-title',[_vm._v(_vm._s(item.text))])],1)}),1)],1),_vm._l((_vm.items5),function(item,i){return _c('v-btn',{key:'U' + i,staticClass:"bgsel",attrs:{"active-class":!_vm.isScrolling ? 'primary--text' : undefined,"text":"","to":item.to}},[_c('span',{domProps:{"textContent":_vm._s(item.text)}})])})],2),_c('div',{staticClass:"mt-5"},[_c('v-menu',{attrs:{"offset-y":"","open-on-hover":"","transition":"slide-y-transition","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"active-class":!_vm.isScrolling ? 'primary--text' : undefined,"text":""}},'v-btn',attrs,false),on),[_vm._v(" Dominios ")])]}}],null,false,1320365713)},[_c('v-list',{attrs:{"flat":""}},_vm._l((_vm.items2),function(item,index){return _c('v-list-item',{key:index,staticClass:"bgsel",attrs:{"to":item.to}},[_c('v-list-item-title',[_vm._v(_vm._s(item.text))])],1)}),1)],1)],1),_c('div',{staticClass:"mt-5"},_vm._l((_vm.items3),function(item,i){return _c('v-btn',{key:i,attrs:{"active-class":!_vm.isScrolling ? 'primary--text' : undefined,"to":item.to,"text":""}},[_c('span',{domProps:{"textContent":_vm._s(item.text)}})])}),1),_c('div',{staticClass:"mt-5"},[(this.isLogged)?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"active-class":!_vm.isScrolling ? 'primary--text' : undefined,"text":""},on:{"click":_vm.redirectToDashboard}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-view-dashboard")])],1)]}}],null,false,1236338582)},[_c('span',[_vm._v("Administrar perfil")])])]:_vm._e()],2)]):_c('v-app-bar-nav-icon',{attrs:{"aria-label":"Open Navigation Drawer"},on:{"click":_vm.toggleDrawer}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }