<template>

<v-app-bar
  height="70"
  v-scroll="onScroll"
  :color="'uniColor'" 
  :hide-on-scroll="$vuetify.breakpoint.smAndDown"
  app
  dark
  elevate-on-scroll
  fixed
  id="menuPrincipal"
>
    <v-img alt="Nic" class="shrink" max-width="115" src="/static/logoNic.png" />

    <v-spacer />
    <v-toolbar-items v-if="$vuetify.breakpoint.mdAndUp">
      <div class="mt-5">
        <v-btn
          v-for="(item, i) in items"
          :key="i"
          :active-class="!isScrolling ? 'primary--text' : undefined"
          :to="item.to"
          text
        >
          <span v-text="item.text" />
        </v-btn>
      </div>

      <div class="mt-5">
        <v-menu offset-y open-on-hover transition="slide-y-transition" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :active-class="!isScrolling ? 'primary--text' : undefined"
              text
              v-bind="attrs"
              v-on="on"
            >
              Nosotros
            </v-btn>
          </template>

          <v-list flat>
            <v-list-item
              v-for="(item, index) in items1"
              :key="index"
              :to="item.to"
              text
              class="bgsel"
            >
              <!-- <span v-text="item.text" /> -->
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn
          :active-class="!isScrolling ? 'primary--text' : undefined"
          class="bgsel"
          text
          v-for="(item, i) in items5"
          :key="'U' + i"
          :to="item.to"
        >
          <span v-text="item.text" />
         <!-- <router-link class="white--text" :to="{name: 'whois'}">WhoIs</router-link>  -->
        </v-btn>
      </div>
      <div class="mt-5">
        <v-menu offset-y open-on-hover transition="slide-y-transition" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :active-class="!isScrolling ? 'primary--text' : undefined"
              text
              v-bind="attrs"
              v-on="on"
            >
              Dominios
            </v-btn>
          </template>
          <v-list flat>
            <v-list-item
              v-for="(item, index) in items2"
              :key="index"
              :to="item.to"
              class="bgsel"
            >
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <div class="mt-5">
        <v-btn
          v-for="(item, i) in items3"
          :key="i"
          :active-class="!isScrolling ? 'primary--text' : undefined"
          :to="item.to"
          text>
          <span v-text="item.text" />
        </v-btn>
      </div>
      
      <div class="mt-5">
        <template v-if="this.isLogged">
          <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :active-class="!isScrolling ? 'primary--text' : undefined"
              text
              v-bind="attrs"
              v-on="on"
              v-on:click='redirectToDashboard'
            >
              <v-icon>mdi-view-dashboard</v-icon>
            </v-btn>
          </template>
          <span>Administrar perfil</span>
        </v-tooltip>
        </template>
        <!-- <template v-if="!this.isLogged">
          <v-btn
            :active-class="!isScrolling ? 'primary--text' : undefined"
            :to="{ name: 'admin-iniciarsesion' }"
          text
          >
          Iniciar Sesion
          </v-btn>
        </template> -->
      </div>
    </v-toolbar-items>
    <v-app-bar-nav-icon
      v-else
      aria-label="Open Navigation Drawer"
      @click="toggleDrawer"
    />
  </v-app-bar>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  data: () => ({
    isScrolling: false 
  }),

  computed: {
    isLogged: {
      get() {
        return this.$store.getters.isLogged;
      },
    },
    items() {
      return [
        {
          to: "/",
          text: "Inicio",
        },
      ];
    },
     items5() {
       return [{ to: "/whois", text: "WhoIs" }];
     },
    items1() {
      return [
        {
          to: "/quienessomos",
          text: "Quienes Somos",
        },
        {
          to: "/historia",
          text: "Historia",
        },
        // {
        //   to: "/misionvision",
        //   text: "Misión Visión",
        // },
        {
          to: "/valores",
          text: "Valores",
        },
      ];
    },
    items2() {
      return [
        {
          to: "/buscardominio",
          text: "Buscar Dominio",
        },
        {
          to: "/informaciongeneral",
          text: "Información General",
        },
        {
          to: "/PasosDom",
          text: "Pasos Para Obtener Dominio",
        },
        {
          to: "/requisitos",
          text: "Requisitos Para Registro",
        },
      ];
    },
    items3() {
      return [{ to: "/contactenos", text: "Contáctenos" }];
    },
    items4() {
      return [{ to: "/iniciarsesion", text: "ameno dorime" }];
    },
    items6() {
      return [{ to: "/crearusuario" }, { text: "Crear Usuario" }];
    },
  },

  methods: {
    ...mapMutations(["toggleDrawer"]),
    onScroll() {
      this.isScrolling =
        (window.pageYOffset || document.documentElement.scrollTop || 0) > 25;
    },
    redirectToDashboard : function () {
      window.location.href = "/panel";
    },
  },
};
</script>

<style scope>
.bgsel:hover {
  background: #afaeae63;
}

.flm:link {
  text-decoration: none;
}

.v-application a {
  color: #ffffff !important;
}
.v-btn {
  border-radius: 0 !important;
}
.v-text-field .fieldset,
.v-text-field .v-input__control,
.v-text-field .v-input__slot {
  border-radius: 0 !important;
}
#negro {
  color: #000 !important;
  text-decoration: none !important;
}
</style>
